import React, { useEffect, useState } from "react";
import { TextField, Button, Box, Alert } from "@mui/material";
import { useNavigate } from "react-router";
import axios from "axios";

export const Auth = () => {
    const [selectedDate, setSelectedDate] = useState<string | null>('0000-10-1');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const navigate = useNavigate();

    const errors = [
        "Wstyd...",
        "Serio???",
        ":(",
    ];

    const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(event.target.value);
    };

    const handleSubmit = () => {
        if (selectedDate === null) {
            setError("Date cannot be null!");
            return;
        }
        const date = new Date(selectedDate);
        axios
            .post(`${process.env.REACT_APP_API_URL}/validate/date`, {
                timestamp: date.getTime(),
            })
            .then(() => {
                setSuccess(true);
                setTimeout(() => {
                    localStorage.setItem('authorized', 'true');
                    navigate('/select');
                }, 2000);
            })
            .catch(() => {
                setError(errors[Math.floor(Math.random() * errors.length)]);
            });
    };

    useEffect(() => {
        if (localStorage.getItem('authorized') === 'true') {
            navigate('/select');
        }
    }, [navigate]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 4rem)"
        >
            {/* Form Container */}
            <Box width="20rem" display="flex" flexDirection="column" alignItems="center">
                <TextField
                    fullWidth
                    label="Select Date"
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    sx={{ marginBottom: 2 }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleSubmit}
                    sx={{ marginBottom: 2 }}
                >
                    Submit
                </Button>

                {/* Feedback Section */}
                {error && (
                    <Alert
                        severity="error"
                        onClose={() => setError(null)}
                        sx={{ marginBottom: 1, width: "100%" }}
                    >
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert severity="success" onClose={() => setSuccess(false)} sx={{ width: "100%" }}>
                        No pięknie!!!
                    </Alert>
                )}
            </Box>
        </Box>
    );
};
