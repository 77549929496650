import {Favorite, MonetizationOn, Star} from "@mui/icons-material";
import {Box} from "@mui/material";

export const Background = () => {
    const icons = [<MonetizationOn/>, <Favorite/>, <Star/>];
    return (
        <Box className="needs-background">
            {Array.from({length: 2000}, (_, index) => (
                <Box key={index} className="icon-box">
                    {icons[index % icons.length]}
                </Box>
            ))}
        </Box>
    )
}