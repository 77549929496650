import {useEffect, useState} from "react";
import {Need, NeedStatus, Priority} from "../../models/Needs";
import axios from "axios";
import {
    Box,
    Button,
    Container,
    LinearProgress,
    Tooltip,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {NeedItem} from "../../components/NeedItem/NeedItem";
import CreateNeedModal from "../../components/CreateNeedModal/CreateNeedModal";
import "./Needs.scss";

const roses = 'https://daisydiyflowers.com/cdn/shop/files/Rose_SaharaBeigeblush.jpg?v=1686687916&width=1445'

export const Needs = () => {
    const [needs, setNeeds] = useState<Need[]>([]);
    const [createNeedModalOpen, setCreateNeedModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem("authorized") !== "true") {
            window.location.href = "/";
            return;
        }

        axios
            .get(`${process.env.REACT_APP_API_URL}/needs`)
            .then((r) => {
                setNeeds(r.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const handleDeleteNeed = (id: string) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/needs/${id}`)
            .then(() => {
                const updatedNeeds = needs.filter((need) => need._id !== id);
                setNeeds(updatedNeeds);
            })
            .catch((e) => console.log(e));
    };

    const handleCreateNeed = (newNeed: Need) => {
        if (needs.length >= 15) {
            alert("You can only create up to 15 needs.");
            return;
        }

        axios
            .post(`${process.env.REACT_APP_API_URL}/needs`, newNeed)
            .then((need) => {
                const updatedNeeds = [...needs, need.data];
                setNeeds(updatedNeeds);
            })
            .catch((e) => console.log(e));
    };

    const progressValue = (needs.length / 10) * 100;

    const getProgressColor = (value: number) => {
        if (value <= 50) return `rgb(${(value / 50) * 255}, 255, 0)`; // Green to Yellow
        return `rgb(255, ${255 - ((value - 50) / 50) * 255}, 0)`; // Yellow to Red
    };

    const resolvedNeeds = needs.filter((need) => need.resolved === NeedStatus.RESOLVED);
    const unresolvedNeeds = needs.filter((need) => need.resolved !== NeedStatus.RESOLVED);

    return (
        <Container className="needs-page">
            <Box className="header">
                <Tooltip title={"Przydałby się wyższy poziom, na więcej potrzeb..."} placement={"top"} arrow>
                    <Typography variant="h6" sx={{mb: 1}}>
                        Stan mojej cierpliwości
                    </Typography>
                </Tooltip>
                <LinearProgress
                    variant="determinate"
                    value={progressValue}
                    sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: "#f5f5f5",
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: getProgressColor(progressValue),
                        },
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{mt: 2}}
                    onClick={() => setCreateNeedModalOpen(true)}
                    disabled={needs.length >= 15}
                >
                    {needs.length >= 15 ? "Nie przesadzasz Słońce?" : "Nowa potrzeba..."}
                </Button>
            </Box>
            <CreateNeedModal
                open={createNeedModalOpen}
                onClose={() => setCreateNeedModalOpen(false)}
                onCreate={handleCreateNeed}
            />
            <Box className="needs-list" sx={{mt: 3}}>
                <NeedItem key={'1'} need={{
                    _id: "123",
                    name: "Kwiaty",
                    description: "Oczywista oczywistość",
                    priority: Priority.LOW,
                    resolved: NeedStatus.RECURRENT,
                    imageUrl: roses
                }} onDelete={() => {
                }}/>
                {unresolvedNeeds.map((need) => (
                    <NeedItem key={need._id} need={need} onDelete={handleDeleteNeed}/>
                ))}
            </Box>
            <Accordion className='needs-resolved'>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>Zrealizowane</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className="needs-list" sx={{mt: 3}}>
                        {resolvedNeeds.map((need) => (
                            <NeedItem key={need._id} need={need} onDelete={handleDeleteNeed}/>
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Container>
    );
};

