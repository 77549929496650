import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import "./Navbar.scss";
import { useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";

export const Navbar = () => {
  const navigate = useNavigate();
  return (
    <Box className="navbar">
      <Tooltip title="Lepszy poziom -> Lepsze benefity" arrow>
        <Typography variant="h2" className="navbar-content">
          Poziom ∞
        </Typography>
      </Tooltip>
      <IconButton
        aria-label="close"
        onClick={() => navigate("/select")}
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};
