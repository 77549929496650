import React from "react";
import {Box, Typography} from "@mui/material";
import "./Intro.scss"

export const Intro = () => {
    return (
        <Box className='intro'>
            <Typography variant={'h1'}>
                Może adres strony w kopercie nie jest tak romantyczny i oldschoolowy jak normalny list, ale jest równie
                bardzo od serca; a w moim przypadku może i bardziej.
                Mam nadzieję, że Ci sie spodoba i będziesz korzystać (mimo że znalazłaś już jakąś alternatywę :( ).
            </Typography>
            <Typography variant={'h1'}>
                A ja nie mogę się doczekać aż zaprezentuję w końcu
                inne umiejętności, nie tylko te inżynieryjne.
            </Typography>
            <Typography variant={'h2'}>
                I miss you
            </Typography>
            <Typography variant={'h3'}>
                Twój największy fan
            </Typography>
        </Box>
    );
};