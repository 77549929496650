import {useEffect, useState} from "react";
import axios from "axios";
import {
    Box,
    Button,
    Container,
    Typography,
    TextField,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, IconButton,
} from "@mui/material";
import "./Casino.scss";
import InfoIcon from '@mui/icons-material/Info';

export const Casino = () => {
    const [balance, setBalance] = useState<number>(0);
    const [amount, setAmount] = useState<number>(0);
    const [winnings, setWinnings] = useState<number | null>(null);
    const [grid, setGrid] = useState<number[][] | null>(null);
    const [playDialogOpen, setPlayDialogOpen] = useState<boolean>(false);
    const [infoDialogOpen, setInfoDialogOpen] = useState<boolean>(false);
    useEffect(() => {
        if (localStorage.getItem("authorized") !== "true") {
            window.location.href = "/";
            return;
        }

        // Fetch the current balance
        axios
            .get(`${process.env.REACT_APP_API_URL}/casino`)
            .then((response) => {
                setBalance(response.data.balance);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handlePlay = () => {
        if (amount <= 0) {
            alert("Please enter a positive amount.");
            return;
        }

        axios
            .post(`${process.env.REACT_APP_API_URL}/casino`, {amount})
            .then((response) => {
                setWinnings(response.data.winnings);
                setGrid(response.data.grid);
                setBalance(response.data.new_balance);
                setPlayDialogOpen(true);
            })
            .catch((error) => {
                console.log(error);
                alert(error.response.data.detail || "An error occurred.");
            });
    };

    const renderInfoDialog = () => (
        <Dialog
            open={infoDialogOpen}
            onClose={() => setInfoDialogOpen(false)}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle>Zasady</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    Nie przegraj wszystkiego Słońce
                </Typography>
                <Typography variant="body2" gutterBottom>
                    - Dopasuj co najmniej trzy takie same symbole, aby wygrać.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Prawdopodobieństwa:
                </Typography>
                <Typography variant="body2" gutterBottom>
                    - Wygrana 100x twojej stawki: szansa 0,01%
                </Typography>
                <Typography variant="body2" gutterBottom>
                    - Wygrana 25x twojej stawki: szansa 0,1%
                </Typography>
                <Typography variant="body2" gutterBottom>
                    - Wygrana 10x twojej stawki: szansa 0,5%
                </Typography>
                <Typography variant="body2" gutterBottom>
                    - Wygrana 5x twojej stawki: szansa 2%
                </Typography>
                <Typography variant="body2" gutterBottom>
                    - Wygrana 2x twojej stawki: szansa 7%
                </Typography>
                <Typography variant="body2" gutterBottom>
                    - Wygrana 1x twojej stawki: szansa 20%
                </Typography>
                <Typography variant="body2" gutterBottom>
                    - Brak wygranej: szansa 70,39%
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setInfoDialogOpen(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );

    // Helper function to get beige shades based on tile value
    const getColorForValue = (value: number) => {
        switch (value) {
            case 0:
                return "#FFF8DC"; // Cornsilk (lightest beige)
            case 1:
                return "#F5F5DC"; // Beige
            case 2:
                return "#DEB887"; // Burlywood
            case 5:
                return "#D2B48C"; // Tan
            case 10:
                return "#C3B091"; // Khaki Web
            case 25:
                return "#A0522D"; // Sienna (darkest beige)
            case 100:
            default:
                return "#E0E0D1"; // Light Grayish Beige for zeros
        }
    };

    // Adjust text color based on background brightness for readability
    const getTextColorForValue = (value: number) => {
        // Use black text for lighter backgrounds, white for darker ones
        if (value === 1 || value === 2 || value === 5 || value === 0) {
            return "#000"; // Black text
        } else {
            return "#fff"; // White text
        }
    };

    const renderGrid = () => {
        if (!grid) return null;

        return (
            <Box sx={{mt: 2}}>
                {grid.map((row, rowIndex) => (
                    <Box
                        key={`row-${rowIndex}`}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 1,
                        }}
                    >
                        {row.map((cell, cellIndex) => (
                            <Paper
                                key={`cell-${rowIndex}-${cellIndex}`}
                                sx={{
                                    padding: 1,
                                    textAlign: "center",
                                    width: "40px",
                                    height: "40px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginRight: cellIndex < row.length - 1 ? 1 : 0,
                                    backgroundColor: getColorForValue(cell),
                                    color: getTextColorForValue(cell),
                                }}
                            >
                                <Typography variant="body1">{cell}</Typography>
                            </Paper>
                        ))}
                    </Box>
                ))}
            </Box>
        );
    };

    return (
        <Container className="casino-page">
            <Box className="header" sx={{textAlign: "center", mt: 4}}>
                <Typography variant="h4" sx={{mb: 2}}>
                    ZDRAAAAAPKIIII
                    <IconButton
                        aria-label="info"
                        onClick={() => setInfoDialogOpen(true)}
                        sx={{ml: 1}}
                    >
                        <InfoIcon/>
                    </IconButton>
                </Typography>
                <Typography variant="h6">
                    Portfel (ciekawe czyj): <strong>{balance}</strong>
                </Typography>
                <Box sx={{mt: 3, display: "flex", justifyContent: "center"}}>
                    <TextField
                        label="Enter Amount"
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(Number(e.target.value))}
                        sx={{mr: 2, width: "200px"}}
                    />
                    <Button variant="contained" color="primary" onClick={handlePlay}>
                        Play
                    </Button>
                </Box>
            </Box>
            {winnings !== null && (
                <Box className="results" sx={{mt: 4, textAlign: "center"}}>
                    <Typography variant="h6">
                        You won: <strong>{winnings}</strong>
                    </Typography>
                    {renderGrid()}
                </Box>
            )}
            <Dialog
                open={playDialogOpen}
                onClose={() => setPlayDialogOpen(false)}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Game Results</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">
                        You bet: <strong>{amount}</strong>
                    </Typography>
                    <Typography variant="h6">
                        You won: <strong>{winnings}</strong>
                    </Typography>
                    <Typography variant="h6">
                        New Balance: <strong>{balance}</strong>
                    </Typography>
                    {renderGrid()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPlayDialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {renderInfoDialog()}
        </Container>
    );
};
