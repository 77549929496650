export enum Priority {
    LOW = "low",
    MEDIUM = "medium",
    HIGH = "high"
}

export enum NeedStatus {
    RESOLVED = "resolved",
    UNRESOLVED = "unresolved",
    RECURRENT = "recurrent"
}

export interface Need {
    _id: string;
    name: string;
    description: string;
    url?: string;
    imageUrl?: string;
    priority: Priority;
    resolved: NeedStatus;
}
