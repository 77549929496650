import {useEffect, useState} from "react";
import {Need, NeedStatus, Priority} from "../../models/Needs";
import axios from "axios";
import {
    Box,
    Button,
    Container,
    LinearProgress,
    Tooltip,
    Typography,
} from "@mui/material";
import {NeedItem} from "../../components/NeedItem/NeedItem";
import CreateNeedModal from "../../components/CreateNeedModal/CreateNeedModal";

export const Christmas = () => {
    const [needs, setNeeds] = useState<Need[]>([]);
    const [createNeedModalOpen, setCreateNeedModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (localStorage.getItem("authorized") !== "true") {
            window.location.href = "/";
            return;
        }

        axios
            .get(`${process.env.REACT_APP_API_URL}/christmas`)
            .then((r) => {
                setNeeds(r.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    const handleDeleteNeed = (id: string) => {
        axios
            .delete(`${process.env.REACT_APP_API_URL}/christmas/${id}`)
            .then(() => {
                const updatedNeeds = needs.filter((need) => need._id !== id);
                setNeeds(updatedNeeds);
            })
            .catch((e) => console.log(e));
    };

    const handleCreateNeed = (newNeed: Need) => {
        if (needs.length >= 25) {
            alert("You can only create up to 25 needs.");
            return;
        }

        axios
            .post(`${process.env.REACT_APP_API_URL}/christmas`, newNeed)
            .then((need) => {
                const updatedNeeds = [...needs, need.data];
                setNeeds(updatedNeeds);
            })
            .catch((e) => console.log(e));
    };

    const progressValue = (needs.length / 25) * 100;

    const getProgressColor = (value: number) => {
        if (value <= 50) return `rgb(${(value / 50) * 255}, 255, 0)`; // Green to Yellow
        return `rgb(255, ${255 - ((value - 50) / 50) * 255}, 0)`; // Yellow to Red
    };

    const unresolvedNeeds = needs.filter((need) => need.resolved !== NeedStatus.RESOLVED);

    return (
        <Container className="needs-page">
            <Box className="header">
                <Typography variant="h4" sx={{mb: 2}}>
                    Tylko tak, żeby mi się do walizki zmieściło OK?
                </Typography>
                <Tooltip title={"Przydałby się wyższy poziom, na więcej potrzeb..."} placement={"top"} arrow>
                    <Typography variant="h6" sx={{mb: 1}}>
                        {needs.length}/25 potrzeb
                    </Typography>
                </Tooltip>
                <LinearProgress
                    variant="determinate"
                    value={progressValue}
                    sx={{
                        height: 10,
                        borderRadius: 5,
                        backgroundColor: "#f5f5f5",
                        "& .MuiLinearProgress-bar": {
                            backgroundColor: getProgressColor(progressValue),
                        },
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    sx={{mt: 2}}
                    onClick={() => setCreateNeedModalOpen(true)}
                    disabled={needs.length >= 25}
                >
                    {needs.length >= 25 ? "Już serio starczy co?" : "Znowu coś..."}
                </Button>
            </Box>
            <CreateNeedModal
                open={createNeedModalOpen}
                onClose={() => setCreateNeedModalOpen(false)}
                onCreate={handleCreateNeed}
            />
            <Box className="needs-list" sx={{mt: 3}}>
                {unresolvedNeeds.map((need) => (
                    <NeedItem key={need._id} need={need} onDelete={handleDeleteNeed}/>
                ))}
            </Box>
        </Container>
    );
};

