import React, {useState} from 'react';
import {Need} from '../../models/Needs';
import {Typography, Box, Button, Modal, IconButton, Tooltip} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';
import './NeedItem.scss';

interface INeedItem {
    need: Need;
    onDelete: (id: string) => void;
    id?: string;
}

export const NeedItem: React.FC<INeedItem> = ({need, onDelete, id}) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleModalOpen = () => {
        if (modalOpen) {
            setModalOpen(false);
        }
        setModalOpen(true);
    };
    const handleModalClose = () => setModalOpen(false);

    const getStatusIcon = () => {
        switch (need.resolved.toLowerCase()) {
            case 'resolved':
                return <CheckCircleIcon color="success"/>;
            case 'recurrent':
                return <ReplayIcon color="warning"/>;
            default:
                return null;
        }
    };

    return (
        <>
            <Box
                className="needItem"
                id={id}
                onClick={handleModalOpen}
                sx={{cursor: 'pointer', p: 2, border: '1px solid #ddd', borderRadius: 1, mb: 2}}
            >
                {need.imageUrl &&
                    <img className="needItem-image" src={need.imageUrl} alt={need.name}/>
                }
                <Box className="needItem-text"
                     sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Typography variant="h5" component="div">
                        {need.name}
                    </Typography>
                    <Tooltip title={need.resolved}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>{getStatusIcon()}</Box>
                    </Tooltip>
                </Box>
            </Box>

            <Modal open={modalOpen} onClose={handleModalClose}>
                <div className='needItem-modal'>

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: 2,
                        }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleModalClose}
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                        {need.imageUrl &&
                            <img className="needItem-modal-image" src={need.imageUrl} alt={need.name}/>
                        }
                        <Box
                            className='needItem-modal-text'
                        >

                            <Typography variant="h6" component="h2">
                                {need.name}
                            </Typography>
                            <Typography sx={{mt: 2}}>{need.description}</Typography>
                            {need.url && (
                                <Typography sx={{mt: 2}}>
                                    <strong>URL:</strong>{' '}
                                    <a href={need.url} target="_blank" rel="noopener noreferrer">
                                        Link
                                    </a>
                                </Typography>
                            )}
                            <Typography sx={{mt: 2}}>
                                <strong>Status:</strong> {need.resolved}
                            </Typography>
                            <Button
                                variant="outlined"
                                color="error"
                                startIcon={<DeleteIcon/>}
                                sx={{mt: 3}}
                                onClick={() => {
                                    onDelete(need._id);
                                    handleModalClose();
                                }}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </div>
            </Modal>
        </>
    );
};
