import {ThemeProvider} from "@emotion/react";
import React from "react";
import {Routes, Route} from "react-router";
import "./App.scss";
import theme from "./utils/theme";
import {Auth} from "./pages/Auth/Auth";
import {ToolSelection} from "./pages/ToolSelection/ToolSelection";
import {Box} from "@mui/material";
import {Navbar} from "./components/Navbar/Navbar";
import {Background} from "./components/Background";
import {Needs} from "./pages/Needs/Needs";
import {Christmas} from "./pages/Christmas/Christmas";
import {Intro} from "./pages/Intro/Intro";
import {Riddle} from "./pages/Riddle/Riddle";
import {Casino} from "./pages/Casino/Casino";

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <Background/>
                <Box className="app-context">
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<Auth/>}></Route>
                        <Route path="/select" element={<ToolSelection/>}></Route>
                        <Route path="/needs" element={<Needs/>}></Route>
                        <Route path="/christmas" element={<Christmas/>}></Route>
                        <Route path={"/intro"} element={<Intro/>}></Route>
                        <Route path={"/riddle"} element={<Riddle/>}></Route>
                        <Route path={"/casino"} element={<Casino/>}></Route>
                        <Route path={"*"} element={<Auth/>}></Route>
                    </Routes>
                </Box>
            </ThemeProvider>
        </div>
    );
}

export default App;
