import React, {useState, useEffect} from 'react';
import {
    Button,
    Modal,
    Box,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';
import {Need, NeedStatus, Priority} from "../../models/Needs";
import {PiCowDuotone} from "react-icons/pi";
import "./CreateNeedModal.scss";

interface CreateNeedModalProps {
    open: boolean;
    onClose: () => void;
    onCreate: (newNeed: Need) => void;
    christmas?: boolean;
}

const CreateNeedModal: React.FC<CreateNeedModalProps> = ({open, onClose, onCreate, christmas}) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Submission loading state
    const steps = ['Name', 'Description', 'URL', 'Image URL', 'Priority', 'Status'];

    // Form fields
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [url, setUrl] = useState<string>('');
    const [imageUrl, setImageUrl] = useState<string>(''); // New field
    const [priority, setPriority] = useState<Priority>(Priority.MEDIUM);
    const [resolved, setResolved] = useState<NeedStatus>(NeedStatus.UNRESOLVED);

    const loadingTexts = [
        "Sprawdzanie co to jest",
        "Sprawdzanie stanu konta",
        "Ojej",
        "Sprawdzanie drugiego konta",
        "Sukces"
    ];

    const stepTexts: Record<number, string[]> = {
        0: ["Co to będzie...", "Ciekawe co tym razem"],
        1: ["Coś do dodania?"],
        2: ["Tak żebym to znalazł"],
        3: ["Dodaj URL do obrazka, żeby ładnie na stronie wyglądało"], // Updated for image URL
        4: ["Jak bardzo nie da się bez tego żyć"],
        5: ["Zrealizowane czy nie?"],
    };

    const [currentStepText, setCurrentStepText] = useState<string>(stepTexts[0][0]);
    const [currentLoadingText, setCurrentLoadingText] = useState<string>(loadingTexts[0]);

    useEffect(() => {
        if (isSubmitting) {
            let textIndex = 0;

            const loadingInterval = setInterval(() => {
                textIndex = (textIndex + 1) % loadingTexts.length;
                setCurrentLoadingText(loadingTexts[textIndex]);
            }, 1500);

            setTimeout(() => {
                setIsSubmitting(false);
                clearInterval(loadingInterval);
                finalizeSubmission();
                setCurrentLoadingText(loadingTexts[0]);
            }, loadingTexts.length * 1500); // Simulate submission delay


            return () => clearInterval(loadingInterval);
        }
    }, [isSubmitting]);

    useEffect(() => {
        // Randomly select text for the current step
        const randomTextIndex = Math.floor(Math.random() * stepTexts[activeStep].length);
        setCurrentStepText(stepTexts[activeStep][randomTextIndex]);
    }, [activeStep]);

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((prev) => prev + 1);
        } else {
            setIsSubmitting(true);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prev) => prev - 1);
        }
    };

    const finalizeSubmission = () => {
        const newNeed: Need = {
            name,
            description,
            url,
            imageUrl, // Include imageUrl
            priority,
            resolved,
            _id: '',
        };

        onCreate(newNeed);
        handleClose();
    };

    const handleClose = () => {
        onClose();
        // Reset form
        setActiveStep(0);
        setName('');
        setDescription('');
        setUrl('');
        setImageUrl(''); // Reset imageUrl
        setPriority(Priority.MEDIUM);
        setResolved(NeedStatus.UNRESOLVED);
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                {isSubmitting ? (
                    <Box sx={{textAlign: 'center'}}>
                        <PiCowDuotone size={48} className='cow'/>
                        <Typography variant="subtitle1" sx={{mt: 2}}>
                            {currentLoadingText}
                        </Typography>
                    </Box>
                ) : (
                    <>
                        <Typography variant="h6" component="h2" sx={{mb: 2}}>
                            Nowa potrzeba
                        </Typography>

                        <Typography variant="subtitle1" sx={{mb: 2}}>
                            {currentStepText}
                        </Typography>

                        {/* Step Content */}
                        {activeStep === 0 && (
                            <TextField
                                fullWidth
                                label="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                margin="normal"
                            />
                        )}
                        {activeStep === 1 && (
                            <TextField
                                fullWidth
                                label="Description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                margin="normal"
                            />
                        )}
                        {activeStep === 2 && (
                            <TextField
                                fullWidth
                                label="URL"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                margin="normal"
                            />
                        )}
                        {activeStep === 3 && (
                            <TextField
                                fullWidth
                                label="Image URL"
                                value={imageUrl}
                                onChange={(e) => setImageUrl(e.target.value)}
                                margin="normal"
                            />
                        )}
                        {activeStep === 4 && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Priority</InputLabel>
                                <Select
                                    value={priority}
                                    onChange={(e) => setPriority(e.target.value as Priority)}
                                    label="Priority"
                                >
                                    <MenuItem value={Priority.LOW}>Low</MenuItem>
                                    <MenuItem value={Priority.MEDIUM}>Medium</MenuItem>
                                    <MenuItem value={Priority.HIGH}>High</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        {activeStep === 5 && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={resolved}
                                    onChange={(e) => setResolved(e.target.value as NeedStatus)}
                                    label="Status"
                                >
                                    <MenuItem value={NeedStatus.UNRESOLVED}>Unresolved</MenuItem>
                                    <MenuItem value={NeedStatus.RESOLVED}>Resolved</MenuItem>
                                </Select>
                            </FormControl>
                        )}

                        {/* Navigation Buttons */}
                        <Box sx={{mt: 3, display: 'flex', justifyContent: 'space-between'}}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                            >
                                {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default CreateNeedModal;
